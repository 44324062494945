<template>
  <router-link
    :to="typeof route == 'object' ? route : { name: route }"
    class="primary--text text--lighten-2 text-decoration-none caption"
  >
    <v-icon color="primary lighten-2" left small>mdi-chevron-left-circle</v-icon>
    <span class="font-weight-medium text-">{{ text }}</span>
  </router-link>
</template>

<script>

export default {
  props: ['text', 'route']
}

</script>
